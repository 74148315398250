import './not-found.page.scss';

function NotFoundPage() {

  return (
    <>
      <section className="page not-found flex flex-ch flex-cv flex-wr">
        <div className="box">
          <h1>Erro 404</h1>
          <h2>Página não encontrada</h2>
        </div>
      </section>
    </>
  );
}

export default NotFoundPage;
